<!--充值明细-->
<template>
  <div class="balance">

    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA',
      color: '#000000',
    }">
      <el-table-column label="充值时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="套餐名称" align="center" prop="packageName">
      </el-table-column>
      <el-table-column label="充值金额" align="center">
        <template slot-scope="scope">
          <span>{{'￥'+scope.row.packageAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送" align="center">
        <template slot-scope="scope">
          <span>{{'￥'+scope.row.giveAmount+(scope.row.remarks?'，'+scope.row.remarks:'') }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { getIdroomList } from "@/api/order/order";
import {

  getRechargeOrder

} from "@/api/recharge/packageSetting.js";
export default {
  data() {
    return {
      pickerOptions,
      dateToDate: [], //日期
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "",
        shopId: "",
        memberId: "",

      },
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.tableParams.memberId = this.$route.query.userId;
     this.getRechargeOrder();
  },
  mounted() {
    this.getIdroomList(); //【请求】房间列表
  },
  methods: {
    // 充值明细
    getRechargeOrder() {
     
      getRechargeOrder(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】房间列表
    getIdroomList() {
      let data = this.tableParams;
      getIdroomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRechargeOrder();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRechargeOrder();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.balance {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search_L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>